@import '../../../../scss/theme-bootstrap';

.site-header {
  .header-transition & {
    @include transition(transform 0.25s ease);
  }
  // Hide the sticky Nav when scrolling down.
  .site-header-formatter--hidden.site-header-formatter--sticky & {
    @include transform(translateY(-100%));
  }
}